<template>
  <div class="relative">
    <div class="relative">
      <label v-if="hasDefaultSlot" :for="inputId" class="label block mb-2 font-bold"
        ><slot
      /></label>
      <div :class="['relative', 'control', icon ? 'has-icons-left' : '']">
        <input
          class="input w-full p-2 border-gray-300 rounded focus:outline-none focus:ring-gray-900 focus:border-gray-900"
          :id="inputId"
          :class="[{ 'has-error pr-8': isValid === false }, inputClass]"
          :type="type === 'password' ? passType : type"
          :name="name"
          :autocomplete="autocomplete"
          :value="value"
          :autofocus="autofocus"
          :ref="name"
          :placeholder="placeholder"
          :aria-label="placeholder"
          @change="changeEvent"
          @input="inputEvent"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          @keyup.enter="keyupEnter"
          @keyup="keyup"
          :readonly="readonly"
          :required="required"
          :accept="accept"
        />
        <span class="icon is-small is-left" v-if="icon">
                      <i :class="['fas', icon]"></i>
                    </span>
      </div>
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from "./ValidationMessages.vue";
import Validation from "../mixins/Validation.js";

export default {
  name: "BaseInput",
  mixins: [Validation],
  components: {
    ValidationMessages,
  },
  data() {
    return {
      passType: "password",
      iconActive: false,
      isDirty: false,
    };
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
  },
  props: {
    inputId: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    accept: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    autocomplete: {
      type: String,
      required: false,
      default: "",
    },
    focus: {
      type: Boolean,
      required: false,
      default: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputClass: {
      type: String,
      required: false,
      default: "",
    },
    validation: {
      type: Object,
      required: false,
      default: () => {},
    },
    validations: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    togglePassType() {

    },
    // setFocus sets focus on a field which has a value of 'ref' tag equal to fieldName
    setFocus(fieldName) {
      if (this.name === fieldName) {
        this.$refs[this.name].focus();
      }
    },
    keyup($event) {
      this.$emit("keyup", $event);
      this.isDirty = true;
    },
    keyupEnter($event) {
      this.$emit("keyup.enter", $event.target.value);
      this.isDirty = true;
    },
    inputEvent($event) {
      this.$emit("input", $event.target.value);
      this.isDirty = true;
    },
    changeEvent($event) {
      this.$emit("change", $event);
    },
  },
  created() {
    if (this.type === "password") {
      this.iconActive = true;
    }
  },
  mounted() {
    if (this.focus) {
      this.$refs[this.name].focus();
    }
  },
};
</script>

<style lang="css" scoped>
input {
  @apply border;
}
.icon {
  position: absolute;
  right: 12px;
  top: 30%;
}

.icon-md {
  font-size: 21px;
  font-weight: 300;
  transform: scale(1.2, 1);
}

.has-error {
  @apply border-red;
}
</style>
