/* eslint-disable no-useless-escape */
import { required } from 'vuelidate/lib/validators'

export const isPhone = (value) => {
  return !required(value) || /^[0-9\s\-+s\(\)]{9,}$/.test(value)
}

export const isFormattedPhone = (value) => {
  const reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

  return value ? reg.test(value) : true
}
