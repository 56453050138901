<template>
  <svg class="text-white fill-current spinner icon-md">
    <use xlink:href="/assets/icons.svg#spinner" />
  </svg>
</template>

<script>
export default {
  name: "FormLoader",
};
</script>

<style lang="css" scoped>
.spinner {
  animation: spin 2s infinite linear;
}
</style>
