<template>
  <div class="relative control select-wrapper">
    <label v-if="hasDefaultSlot" :for="id" class="label block mb-2 font-bold"
      ><slot
    /></label>
    <select
      class="simple-native"
      :id="id"
      :class="[{ 'has-error': isValid === false }, inputClass]"
      :name="name"
      :autocomplete="autocomplete"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @change="$emit('change', $event.target.value)"
      @input="$emit('input', $event.target.value)"
    >
      <option value disabled selected v-if="usePlaceholder">
        {{ placeholder }}
      </option>
      <option
        v-for="(option, key) in options"
        :key="key"
        :value="option.value"
        :disabled="!!option.disabled"
        v-bind="{ selected: option.value === selected }"
      >
        {{ option.label }}
      </option>
    </select>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from "./ValidationMessages.vue";
import Validation from "../mixins/Validation.js";

export default {
  name: "BaseSelect",
  mixins: [Validation],
  components: {
    ValidationMessages,
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    selected: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    usePlaceholder: {
      type: Boolean,
      required: false,
      default: true,
    },
    autocomplete: {
      type: String,
      required: false,
      default: "",
    },
    inputClass: {
      type: String,
      required: false,
      default: "",
    },
    validations: {
      type: Array,
      default: () => [],
    },
  },
  methods: {},
};
</script>

<style lang="css" scoped>
.simple-native {
  color: #363636;
  background-color: #F1F1F1;
  border: 1px solid transparent;
  height: 60px;
  line-height: 58px;
  @apply block w-full py-2 pl-3 pr-10 mt-1 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-black;
}
.has-error {
  @apply border-red;
}
</style>
