<template>
  <div v-if="validations">
    <span
      v-for="(validation, index) in computedValidations"
      :key="index"
      class="block text-red mt-3"
      :class="validation.condition ? 'errorMessage' : ''"
      data-testid="errorMessage"
    >
      <span v-if="validation.condition">
        {{ validation.text }}
      </span>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    validations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    computedValidations: function() {
      return this.validations
    }
  }
}
</script>
