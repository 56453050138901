<template>
  <component
    class="relative inline-block p-5 text-xs font-bold uppercase whitespace-no-wrap border-2 outline-none group underline-none rounded-0 hover:text-white focus:outline-none tracking-md"
    :is="link ? 'router-link' : 'button'"
    :class="[
      `border-black`,
      `text-black`,
      `hover:bg-black`,
      { loading: loading },
    ]"
    :type="link ? '' : type"
    :to="link"
    :disabled="disabled"
    id="button"
  >
    <span :style="loading ? { visibility: 'hidden' } : {}">
      <slot> Button </slot>
    </span>
    <loader :class="`text-black group-hover:text-white`" v-if="loading" />
  </component>
</template>

<script>
import FormLoader from "./FormLoader.vue";

export default {
  name: "ButtonOutline",
  mounted() {},
  props: {
    type: {
      type: String,
      required: false,
      default: "button",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    link: {
      type: [Object, String],
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    FormLoader,
  },
};
</script>

<style lang="css" scoped>
#button {
  border-style: inherit;
}
.loading svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  display: inline-block;
}

button[disabled],
a[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
